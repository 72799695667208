import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import Home from "./pages/Home";
import Servizi from "./pages/Servizi";
import Collezioni from "./pages/Collezioni";
import Eventi from "./pages/Eventi";
import BioProfilo from "./pages/BioProfilo";
import BioDesigner from "./pages/BioDesigner";
import Navbar from "./components/molecules/NavBar/Navbar";
import Footer from "./components/molecules/Footer";
import AllContent from "./pages/AllContent";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
});

const MainLayout = () => (
  <>
    <Navbar />
    <Outlet />
    <Footer />
  </>
);

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route element={<MainLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/profilo" element={<BioProfilo />} />
          <Route path="/servizi" element={<Servizi />} />
          <Route path="/eventi" element={<Eventi />} />
          <Route path="/collezioni" element={<Collezioni />} />
          <Route path="/all-content" element={<AllContent />} />
        </Route>
      </Routes>
    </QueryClientProvider>
  );
}

export default App;
