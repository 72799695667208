import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  useTheme,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import useCollezioni from "../models/useCollezioni";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Collezioni = () => {
  const { collezioni, loading, error } = useCollezioni();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (error) {
    return <Typography>Errore nel caricamento dei dati.</Typography>;
  }
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "55vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: "60px" }}>
      <>
        {/* Immagine di Apertura */}
        {collezioni.immagineDiapertura && (
          <Box
            component="img"
            src={collezioni.immagineDiapertura.fields.file.url}
            alt="Opening Collection"
            sx={{
              width: "100%",
              height: "40vh",
              marginBottom: 4,
              objectFit: "cover",
              marginTop: { xs: 4, md: 6 },
            }}
          />
        )}

        {/* Descrizione e Immagini */}
        <Grid container spacing={6} alignItems="flex-start" marginTop="0px">
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{ mb: 4 }}
              textAlign={"center"}
            >
              {collezioni.descriptionTitle}
            </Typography>

            <Typography
              variant="body1"
              paragraph
              textAlign={"justify"}
              lineHeight="2"
            >
              {documentToReactComponents(collezioni.descriptionText)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {collezioni.immagineLaterale1 && (
              <Box
                component="img"
                src={collezioni.immagineLaterale1.fields.file.url}
                alt="Image 1"
                sx={{
                  width: "100%",
                  height: "40vh",
                  marginBottom: 2,
                  objectFit: "cover",
                }}
              />
            )}
            {collezioni.immagineLaterale2 && (
              <Box
                component="img"
                src={collezioni.immagineLaterale2.fields.file.url}
                alt="Image 2"
                sx={{ width: "100%", height: "40vh", objectFit: "cover" }}
              />
            )}
          </Grid>
        </Grid>

        {/* Sezioni delle Collezioni */}
        {collezioni?.collezioni && collezioni.collezioni.length > 0 ? (
          collezioni.collezioni.map(
            (collection) =>
              collection.fields && (
                <Box key={collection.sys.id} sx={{ marginTop: "100px" }}>
                  <Grid container spacing={4} alignItems="stretch">
                    <Grid item xs={12} md={6}>
                      {collection.fields.collectionImage ? (
                        <Box
                          component="img"
                          src={
                            collection.fields.collectionImage.fields.file.url
                          }
                          alt={collection.fields.collectionName}
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      ) : (
                        console.warn(
                          "Missing collectionImage for collection",
                          collection
                        )
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                        }}
                      >
                        <Box height={isMobile ? "35%" : "20%"}>
                          <Typography
                            variant="h4"
                            gutterBottom
                            fontFamily={theme.typography.bodoniFont}
                            fontSize={theme.typography.h4.fontSize}
                            fontWeight={600}
                            sx={{
                              textAlign: "right",
                              marginRight: 2,
                              color: collection.fields.titleColor || "inherit",
                            }}
                          >
                            {collection.fields.collectionName}
                          </Typography>
                          <Typography
                            variant="body1"
                            gutterBottom
                            paragraph
                            lineHeight={2}
                            sx={{ textAlign: "right", marginRight: 2 }}
                          >
                            {collection.fields.collectionSubtitle}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            backgroundColor:
                              collection.fields.backgroundColor ||
                              "rgba(0, 0, 0, 0.05)",
                            padding: 4,
                            marginTop: 2,
                            flexGrow: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Typography
                            variant="body1"
                            paragraph
                            lineHeight={1.8}
                            fontWeight="600"
                            textAlign={"justify"}
                            fontFamily={theme.typography.cursiveFont}
                            sx={{ color: "white" }}
                          >
                            {collection.fields.collectionDescription}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>

                  {/* Immagine grande sotto la descrizione */}
                  {collection.fields.collectionLargeImage && (
                    <Box
                      component="img"
                      src={
                        collection.fields.collectionLargeImage.fields.file.url
                      }
                      alt="Collection Image 2"
                      sx={{
                        width: "100%",
                        height: "auto",
                        marginTop: 4,
                        objectFit: "cover",
                      }}
                    />
                  )}

                  {/* Tre immagini affiancate */}
                  {/* Tre immagini affiancate */}
                  <Grid container spacing={4} sx={{ marginTop: 4 }}>
                    <Grid item xs={12} md={4}>
                      {collection.fields.sideImageI && (
                        <Box
                          component="img"
                          src={collection.fields.sideImageI.fields.file.url}
                          alt="Collection Image 1"
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {collection.fields.sideImageSecondo && (
                        <Box
                          component="img"
                          src={
                            collection.fields.sideImageSecondo.fields.file.url
                          }
                          alt="Collection Image 2"
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      {collection.fields.sideImageTerzo && (
                        <Box
                          component="img"
                          src={collection.fields.sideImageTerzo.fields.file.url}
                          alt="Collection Image 3"
                          sx={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>

                  {/* Due immagini affiancate */}
                  <Grid container spacing={4} sx={{ marginTop: 4 }}>
                    <Grid item xs={12} md={6}>
                      {collection.fields.sideImage1 && (
                        <Box
                          component="img"
                          src={collection.fields.sideImage1.fields.file.url}
                          alt="Collection Image 3"
                          sx={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {collection.fields.sideImage2 && (
                        <Box
                          component="img"
                          src={collection.fields.sideImage2.fields.file.url}
                          alt="Collection Image 4"
                          sx={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              )
          )
        ) : (
          <Typography variant="h6" align="center" sx={{ marginTop: 8 }}>
            Nessuna collezione in programma.
          </Typography>
        )}
      </>
    </Container>
  );
};

export default Collezioni;
