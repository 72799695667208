import React from 'react';
import { Box, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MediaModal = ({ open, handleClose, mediaType, mediaUrl }) => {
  return (
    <Modal open={open} onClose={handleClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ position: 'relative', outline: 'none', borderRadius: '8px', overflow: 'hidden', maxWidth: '80vw', maxHeight: '80vh' }}>
        <IconButton sx={{ position: 'absolute', top: 8, right: 8, color: '#fff', zIndex: 1 }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        {mediaType === 'image' ? (
          <img
            src={mediaUrl}
            alt="Ingrandito"
            style={{ width: '100%', height: 'auto', display: 'block', maxWidth: '100%', maxHeight: '100%' }}
          />
        ) : (
          <iframe
            width="100%"
            height="auto"
            src={mediaUrl}
            title="Video Ingrandito"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{ display: 'block', maxWidth: '100%', maxHeight: '100%' }}
          ></iframe>
        )}
      </Box>
    </Modal>
  );
};

export default MediaModal;
