import { Button } from "@mui/material";

const PrimaryButton = ({ children, ...props }) => {
  return (
    <Button
      sx={{
        borderRadius: "50px",
        border: "1px solid black",
        backgroundColor: "transparent",
        color: "black",
        padding: "10px 20px",
        textTransform: "none",
        fontWeight: "bold",
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
          borderColor: "black",
        },
      }}
      {...props}
    >
      {children} {/* Contenuto del bottone */}
    </Button>
  );
};

export default PrimaryButton;
