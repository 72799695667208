import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import eventiStyles from "../styles/EventiStyles"; // Importa gli stili da file separato

import PrimaryButton from "../components/atoms/PrimaryButton";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselComponent from "../components/molecules/Carousel";
import ContactForm from "../components/molecules/ContactForm";
import { useLocation, useNavigate } from "react-router-dom";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import useAllContent from "../models/useAllContent";

const Eventi = () => {
  const theme = useTheme();
  const styles = eventiStyles(theme);
  const navigate = useNavigate();
  const location = useLocation();
  const contactFormRef = useRef(null);
  const { events, images, videos, loading, error } = useAllContent();

  useEffect(() => {
    if (location.state?.scrollToContact) {
      contactFormRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  const validImages = images.filter((image) => image.url !== "");

  const chunkImages = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const imageChunks = useMemo(() => chunkImages(images, 4), [images]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    swipe: true,
    touchMove: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "55vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <Container maxWidth={false} disableGutters sx={styles.container}>
        <>
          {/* Sezione Calendario Eventi */}

          <Grid sx={styles.wrapper}>
            <Grid sx={styles.calendarSection}>
              <Box sx={styles.content}>
                <Typography variant="h3" sx={styles.sectionTitle}>
                  Calendario Eventi
                </Typography>
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                  Agenda degli Eventi: Segui il percorso di Emanuele Ricchena
                </Typography>
                <Box sx={styles.button}>
                  <PrimaryButton onClick={() => navigate("/all-content")}>
                    SCOPRI TUTTI GLI EVENTI
                  </PrimaryButton>
                </Box>
              </Box>

              <Box sx={{ position: "relative", width: "100%" }}>
                <Box sx={styles.transparentBox} />
                <Box sx={styles.rightSide}>
                  {events.slice(0, 3).map((event, index) => (
                    <Box key={index} sx={styles.eventItem} mb={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <Typography
                            variant="h1"
                            sx={{
                              fontSize: "48px",
                              fontWeight: 700,
                              color: "rgba(0, 0, 0, 0.2)",
                              textAlign: "right",
                            }}
                          >
                            {String(index + 1).padStart(2, "0")}
                          </Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Typography variant="h6" sx={{ fontWeight: 400 }}>
                            {event.formattedDate} - {event.time}
                          </Typography>
                          <Typography
                            variant="h5"
                            sx={{
                              fontFamily: "'Bodoni Moda', serif",
                              fontWeight: 700,
                            }}
                          >
                            {event.location}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              mt: 1,
                              display: "-webkit-box",
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {event.description}
                          </Typography>

                          <Box
                            sx={{
                              borderBottom: "1px solid #000",
                              mt: 2,
                              width: "100%",
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* Sezione galleria Eventi */}
          <Grid sx={styles.galleriaWrapper}>
            <Grid sx={styles.calendarSection}>
              <Box sx={{ position: "relative", width: "100%" }}>
                <Box sx={styles.galleriaTransparentBox} />
                <Box sx={styles.sliderContainer}>
                  <CarouselComponent
                    images={validImages}
                    sliderSettings={sliderSettings}
                  />
                </Box>
              </Box>

              <Box sx={styles.content}>
                <Typography variant="h3" sx={styles.sectionTitle}>
                  Galleria Immagini
                </Typography>
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                  Agenda degli Eventi: Segui il percorso di Emanuele Ricchena
                </Typography>
                <Box sx={styles.button}>
                  <PrimaryButton onClick={() => navigate("/all-content")}>
                    VEDI TUTTE LE IMMAGINI
                  </PrimaryButton>
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* Sezione video youtube */}
          <Grid sx={styles.videoWrapper}>
            <Grid sx={styles.calendarSection}>
              <Box sx={styles.content}>
                <Typography variant="h3" sx={styles.sectionTitle}>
                  Video
                </Typography>
                <Typography variant="h5" sx={{ marginBottom: 2 }}>
                  Segui il percorso di Emanuele Ricchena
                </Typography>
                <Box sx={styles.button}>
                  <PrimaryButton onClick={() => navigate("/all-content")}>
                    VEDI TUTTI I VIDEO
                  </PrimaryButton>
                </Box>
              </Box>

              <Box sx={{ position: "relative", width: "100%" }}>
                <Box sx={styles.videoTransparentBox} />
                <Box sx={styles.iframeContainer}>
                  {videos.length > 0 && (
                    <iframe
                      width="646"
                      height="400"
                      src={videos[videos.length - 1].url}
                      title={videos[videos.length - 1].title}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>

        <Box ref={contactFormRef} sx={styles.contactWrapper}>
          <ContactForm />
        </Box>
      </Container>
    );
  }
};

const NextArrow = (props) => {
  const { onClick } = props;
  const theme = useTheme();
  const styles = eventiStyles(theme);
  return (
    <IconButton
      onClick={onClick}
      sx={{ ...styles.arrowButton, ...styles.nextArrow }}
    >
      <ArrowForwardIcon />
    </IconButton>
  );
};

// Freccia indietro
const PrevArrow = (props) => {
  const { onClick } = props;
  const theme = useTheme();
  const styles = eventiStyles(theme);
  return (
    <IconButton
      onClick={onClick}
      sx={{ ...styles.arrowButton, ...styles.prevArrow }}
    >
      <ArrowBackIcon />
    </IconButton>
  );
};

export default Eventi;
