const contactFormStyles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#000000',
    color: '#FFFFFF',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },

  title: {
    marginBottom: theme.spacing(4),
    color: '#FFFFFF', 
  },

  form: {
    width: '100%',
    maxWidth: '800px',
    display: 'flex',
    flexDirection: 'column',
  },

  inputField: {
    marginBottom: theme.spacing(6),
    '& .MuiInputBase-input': {
      color: '#FFFFFF',
    },
    '& .MuiInputLabel-root': {
      color: '#FFFFFF', 
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#FFFFFF',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottomColor: '#FFFFFF',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFFFFF', 
    },
  },
});

export default contactFormStyles;
