import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";

import { GlobalStyles } from "@mui/system";
import { ThemeProvider, CssBaseline } from "@mui/material";
import lightTheme from "./theme/lighTheme";

import { BrowserRouter as Router, useLocation } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

const Main = () => {
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname === "/") {
      document.body.style.backgroundColor = "#000";
    } else {
      document.body.style.backgroundColor = "#FFFDFD";
    }
  }, [location.pathname]);

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />

      <GlobalStyles
        styles={{
          ".css-1w9sqjq-MuiContainer-root": {
            paddingLeft: "0px",
            paddingRight: "0px",
          },
          "@media (min-width: 767px)": {
            ".css-1w9sqjq-MuiContainer-root": {
              paddingLeft: "0px",
              paddingRight: "0px",
            },
          },
        }}
      />

      <App />
    </ThemeProvider>
  );
};

root.render(
  <React.StrictMode>
    <Router>
      <Main />
    </Router>
  </React.StrictMode>
);

reportWebVitals();
