import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { createClient } from "contentful";
import { format, parseISO } from "date-fns";
import { it } from "date-fns/locale";
import { space, accessToken } from "../utils/contentfulConfig";

const useContentfulClient = () => {
  return useMemo(() => createClient({ space, accessToken }), []);
};

const transformYoutubeUrlToEmbed = (url) => {
  const videoId = url.includes("watch?v=")
    ? url.split("v=")[1]
    : url.split("/").pop();
  return `https://www.youtube.com/embed/${videoId}`;
};

export const formatEventDate = (isoDate) => {
  const parsedDate = parseISO(isoDate);
  return format(parsedDate, "d MMMM yyyy - HH:mm'h'", { locale: it });
};

// Funzione per fare fetch dei dati da Contentful
const fetchContentfulData = async (client) => {
  try {
    const [eventCollectionEntry, imageCollectionEntry, videoCollectionEntry] =
      await Promise.all([
        client.getEntries({ content_type: "eventiCollection" }),
        client.getEntries({ content_type: "collezioneDiImmagini" }),
        client.getEntries({ content_type: "collezioneDiVideo" }),
      ]);

    const events =
      eventCollectionEntry.items.length > 0
        ? eventCollectionEntry.items[0].fields.collezioneDiEventi
            .map((event) => {
              const eventVideo = event.fields.eventVideo;
              const isExternalVideo =
                eventVideo?.includes("youtube.com") ||
                eventVideo?.includes("vimeo.com");

              return {
                title: event.fields.eventiTitolo,
                date: event.fields.eventiData,
                formattedDate: formatEventDate(event.fields.eventiData),
                location: event.fields.eventiLuogo,
                description: event.fields.eventDescription,
                image: event.fields.eventiImmagine?.fields.file.url, // Immagine
                video: isExternalVideo
                  ? transformYoutubeUrlToEmbed(eventVideo) // Trasforma URL esterni
                  : event.fields.eventVideo?.fields?.file?.url, // File caricato
                type:
                  isExternalVideo || event.fields.eventVideo
                    ? "video"
                    : "image", // Tipo (video o immagine)
              };
            })
            .sort((a, b) => new Date(a.date) - new Date(b.date))
        : [];

    const images =
      imageCollectionEntry.items.length > 0
        ? imageCollectionEntry.items[0].fields.allImages.map((image) => ({
            title: image.fields.immaginiTitolo,
            category: image.fields.categoriaImmagini,
            url: image.fields.immaginiGalleria?.fields.file.url,
          }))
        : [];

    const videos =
      videoCollectionEntry.items.length > 0
        ? videoCollectionEntry.items[0].fields.allVideos.map((video) => ({
            title: video.fields.videoTitolo,
            category: video.fields.videoCategoria,
            url: transformYoutubeUrlToEmbed(video.fields.videoUrl),
          }))
        : [];

    return { events, images, videos };
  } catch (error) {
    throw new Error("Errore durante il fetch dei dati da Contentful");
  }
};

const useAllContent = () => {
  const client = useContentfulClient();
  const { data, error, isLoading } = useQuery({
    queryKey: ["contentfulData"],
    queryFn: () => fetchContentfulData(client),
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 30,
    refetchOnWindowFocus: false,
  });

  return {
    events: data?.events || [],
    images: data?.images || [],
    videos: data?.videos || [],
    loading: isLoading,
    error,
  };
};

export default useAllContent;
