const allContentStyles = (theme) => ({
  container: {
    paddingTop: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingLeft: theme.spacing(10),
    margin: "0 auto",
    backgroundColor: "#fff",
    color: "#000",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(4),
    textAlign: "center",
    color: "#000",
  },

  eventItem: {
    backgroundColor: "fff",
    padding: theme.spacing(2),
    color: "#000",
  },
  eventTitle: {
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: "#000",
  },
  eventDateLocation: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: "#000",
  },
  eventDescription: {
    color: "#000",
    marginTop: theme.spacing(2),
    textAlign: "justify",
  },
  divider: {
    marginY: theme.spacing(2),
    backgroundColor: "#555",
  },
  gallerySection: {
    marginTop: theme.spacing(8),
    color: "#fff",
  },
  videoSection: {
    marginTop: theme.spacing(8),
    color: "#fff",
  },
  filterContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(4),
    flexWrap: "wrap",
  },
  filterButton: {
    margin: theme.spacing(1),
    color: "#000",
    borderColor: "#000",
    "&.Mui-contained": {
      backgroundColor: "#ccc",
    },
  },
  imageItem: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: "#fff",
    overflow: "hidden",
    cursor: "pointer",
    img: {
      width: "100%",
      borderRadius: "4px",

      transition: "transform 0.3s ease",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  },

  videoItem: {
    padding: theme.spacing(2),

    textAlign: "center",
    color: "#fff",
    overflow: "hidden",
    cursor: "pointer",
    iframe: {
      width: "100%",
      borderRadius: "4px",
      transition: "transform 0.3s ease",
      "&:hover": {
        transform: "scale(1.05)",
      },
    },
  },
});

export default allContentStyles;
