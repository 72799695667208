import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { createClient } from 'contentful';
import { space, accessToken } from '../utils/contentfulConfig';


const useContentfulClient = () => {
  return useMemo(() => createClient({ space, accessToken }), []);
};

const fetchCollezioni = async (client) => {
  const response = await client.getEntries({ content_type: 'pageCollection' });

  if (response.items.length > 0) {
    return response.items[0].fields;
  } else {
    throw new Error("Nessun dato trovato per il content type 'pageCollection'");
  }
};

const useCollezioni = () => {
  const client = useContentfulClient(); 
  const { data, error, isLoading } = useQuery({
    queryKey: ['collezioni'],
    queryFn: () => fetchCollezioni(client), 
    staleTime: 1000 * 60 * 5, 
    cacheTime: 1000 * 60 * 30, 
    refetchOnWindowFocus: false,
  });

  return {
    collezioni: data || null,
    loading: isLoading,
    error,
  };
};

export default useCollezioni;
