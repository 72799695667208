import React from "react";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const HamburgerMenu = ({ onClick, iconColor, backgroundColor, border }) => {
  return (
    <IconButton
      size="large"
      aria-label="menu"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={onClick}
      sx={{
        color: iconColor,
        backgroundColor: backgroundColor,
        borderRadius: "50%",
        marginLeft: "10px",
        "&:hover": {
          backgroundColor: backgroundColor,
        },
        border: border ?? "none",
      }}
    >
      <MenuIcon sx={{ color: iconColor }} />
    </IconButton>
  );
};

export default HamburgerMenu;
