import React, { useState } from "react";
import { TextField, Box, Typography, useTheme } from "@mui/material";
import emailjs from "emailjs-com";
import contactFormStyles from "../../styles/ContactStyles";
import PrimaryButtonWhite from "../atoms/PrimaryButtonWhite";

const ContactForm = () => {
  const theme = useTheme();
  const styles = contactFormStyles(theme);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [touched, setTouched] = useState({
    name: false,
    email: false,
    message: false,
  });

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidForm = () => {
    return (
      formData.name.length >= 2 &&
      isValidEmail(formData.email) &&
      formData.message.length >= 5
    );
  };

  const getErrorMessage = (field) => {
    if (field === "name" && formData.name.length < 2) {
      return "Il nome deve contentere almeno 2 caratteri";
    }
    if (field === "email" && !isValidEmail(formData.email)) {
      return "Inserire un'email valida.";
    }
    if (field === "message" && formData.message.length < 5) {
      return "Il messaggio deve contenere almeno 5 caratteri.";
    }
    return "";
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({
      ...touched,
      [name]: true,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_apdjub7",
        "template_8rl62sf",
        formData,
        "M1t302_lxaf6qTn6j"
      )
      .then(
        (response) => {
          alert("Email inviata con successo!");
          setFormData({ name: "", email: "", message: "" });
        },
        (err) => {
          alert("Invio email fallito. Riprova.");
        }
      );
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h1" sx={styles.title}>
        Contattami
      </Typography>

      <Box
        component="form"
        sx={styles.form}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          label="Il tuo nome"
          name="name"
          variant="standard"
          fullWidth
          value={formData.name}
          onChange={handleChange}
          onBlur={handleBlur}
          sx={styles.inputField}
          helperText={touched.name && getErrorMessage("name")}
          error={touched.name && formData.name.length < 2}
        />

        <TextField
          label="La tua email"
          name="email"
          variant="standard"
          fullWidth
          value={formData.email}
          onChange={handleChange}
          onBlur={handleBlur}
          sx={styles.inputField}
          helperText={touched.email && getErrorMessage("email")}
          error={touched.email && !isValidEmail(formData.email)}
        />

        <TextField
          label="Il tuo messaggio"
          name="message"
          variant="standard"
          multiline
          rows={4}
          fullWidth
          value={formData.message}
          onChange={handleChange}
          onBlur={handleBlur}
          sx={styles.inputField}
          helperText={touched.message && getErrorMessage("message")}
          error={touched.message && formData.message.length < 5}
        />
        <Box display="flex" justifyContent="center">
          <PrimaryButtonWhite disabled={!isValidForm()} type="submit">
            Invia
          </PrimaryButtonWhite>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactForm;
