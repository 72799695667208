import React from "react";
import { Modal, Box, Typography, Grid, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ModalServizi = ({ open, onClose, title, description, images }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 6,
          borderRadius: 2,
          width: "90%", // Larghezza maggiore
          maxWidth: "1200px", // Limite massimo di larghezza
          maxHeight: "90vh", // Altezza massima
          overflowY: "auto", // Scroll interno
          position: "relative", // Necessario per posizionare il pulsante di chiusura
        }}
      >
        {/* Pulsante di chiusura */}
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            color: "grey.600",
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Titolo */}
        <Typography
          id="modal-title"
          variant="h4"
          component="h2"
          sx={{ marginBottom: 5, textAlign: "center" }}
        >
          {title}
        </Typography>

        {/* Contenuto a due colonne */}
        <Grid container spacing={4}>
          {/* Colonna Descrizione */}
          <Grid item xs={12} md={6}>
            <Typography
              id="modal-description"
              variant="body1"
              sx={{ lineHeight: 1.8 }}
            >
              {description}
            </Typography>
          </Grid>

          {/* Colonna Immagini */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              {images && images.length > 0 ? (
                images.map((item, index) => (
                  <Box
                    key={index}
                    component="img"
                    src={item?.fields?.file.url}
                    alt={`Immagine ${index + 1}`}
                    sx={{
                      width: "60%",
                      height: "auto",
                      borderRadius: 2,
                      boxShadow: 3,
                    }}
                  />
                ))
              ) : (
                <Typography variant="body2" color="text.secondary">
                  Nessuna immagine disponibile.
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalServizi;
