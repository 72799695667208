import React from "react";
import {
  Drawer,
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CollectionsDropdown from "./CollectionDropDown";
import { useTheme } from "@emotion/react";

const NavDrawer = ({
  open,
  onClose,
  pages,
  onNavigate,
  onCollectionSelect,
  isHomePage,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  if (!isMobile && !isHomePage) {
    return null;
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          padding: theme.spacing(2),
          width: "50%",
          height: "100%",
          backgroundColor: theme.palette.background.default, // Usa il colore di sfondo dal tema
          color: theme.palette.text.primary, // Testo dinamico in base al tema
        },
      }}
      slotProps={{
        backdrop: {
          sx: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: theme.spacing(2),
        }}
      >
        <Typography variant="h3">Menu</Typography>
        <IconButton
          onClick={onClose}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <CloseIcon
            fontSize="large"
            sx={{ color: theme.palette.text.primary }}
          />
        </IconButton>
      </Box>

      <Divider sx={{ backgroundColor: theme.palette.text.secondary }} />

      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100%",
          marginTop: theme.spacing(2),
          gap: theme.spacing(1),
        }}
      >
        {pages.map((page, index) => (
          <React.Fragment key={page.name}>
            <ListItem
              button
              onClick={() => onNavigate(page)}
              sx={{ padding: theme.spacing(2) }}
            >
              <ListItemText
                primary={page.name}
                primaryTypographyProps={{
                  variant: "h5",
                  color: theme.palette.text.primary,
                }}
              />
            </ListItem>
            {index < pages.length - 1 && (
              <Divider sx={{ backgroundColor: theme.palette.text.secondary }} />
            )}
          </React.Fragment>
        ))}

        <Divider sx={{ backgroundColor: theme.palette.text.secondary }} />

        <React.Fragment>
          <ListItem sx={{ padding: theme.spacing(1) }}>
            <CollectionsDropdown
              onCollectionSelect={onCollectionSelect}
              isMobile={isMobile}
            />
          </ListItem>
        </React.Fragment>
      </List>
    </Drawer>
  );
};

export default NavDrawer;
