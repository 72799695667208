import { useQuery } from "@tanstack/react-query";
import { createClient } from "contentful";
import { space, accessToken } from "../utils/contentfulConfig";
import { useMemo } from "react";

// Memoizzazione del client di Contentful
const useContentfulClient = () => {
  return useMemo(() => createClient({ space, accessToken }), []);
};

// Fetch dei dati di profilo, ricevendo il client come parametro
const fetchProfileContent = async (client) => {
  const response = await client.getEntries({
    content_type: "serviziContent",
  });

  if (response.items.length > 0) {
    return response.items[0];
  }

  throw new Error("No services content available");
};

// Hook personalizzato per gestire il fetch dei dati
const useServicesContent = () => {
  const client = useContentfulClient(); // Ottieni il client memorizzato
  const { data, error, isLoading } = useQuery({
    queryKey: ["serviziContent"],
    queryFn: () => fetchProfileContent(client), // Passa il client come argomento
    staleTime: 1000 * 60 * 5, // Dati freschi per 5 minuti
    cacheTime: 1000 * 60 * 30, // Dati in cache per 30 minuti
    refetchOnWindowFocus: false, // Non refetcha quando si torna alla finestra
  });

  return {
    services: data,
    error,
    loading: isLoading,
  };
};

export default useServicesContent;
