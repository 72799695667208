import React from "react";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import bioProfiloStyles from "../styles/BioProfiloStyles";
import useProfileContent from "../models/useProfileContent";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const BioProfilo = () => {
  const { profile, loading, error } = useProfileContent();
  const theme = useTheme();
  const isMobile = theme.breakpoints.down("md");
  const styles = bioProfiloStyles(theme);

  if (error) {
    return <div>Error loading profile data...</div>;
  }
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "55vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const {
    titolobiografia = "Titolo non disponibile",
    sottotitolo = "Sottotitolo non disponibile",
    biografia = { content: [] },
    fotoProfilo = null,
  } = profile.fields;

  return (
    <Container
      maxWidth="lg"
      sx={{ ...styles.container, paddingTop: isMobile && "60px" }}
    >
      <Grid container spacing={4} sx={styles.gridContainer}>
        <Grid item xs={12} md={6} sx={styles.gridContainer}>
          <Box sx={styles.imageBox}>
            {/* Foto del profilo centrata */}
            {fotoProfilo ? (
              <Box
                component="img"
                src={fotoProfilo.fields.file.url}
                alt="Immagine del Profilo"
                sx={styles.image}
              />
            ) : (
              <div>No Image Available</div>
            )}
            <Box sx={styles.coloredBox} />
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h2" sx={styles.title}>
            {titolobiografia}
          </Typography>

          {/* Renderizza correttamente il Rich Text di Contentful */}
          <Box sx={styles.bioText}>{documentToReactComponents(biografia)}</Box>
          <Box paddingTop="1rem">
            <Typography
              variant="body1"
              fontSize={"1.3rem"}
              lineHeight={"2rem"}
              sx={styles.subtitle}
            >
              {sottotitolo}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default BioProfilo;
