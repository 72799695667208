import React from "react";
import {
  Box,
  Container,
  Grid,
  CircularProgress,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { createClient } from "contentful";
import { space, accessToken } from "../utils/contentfulConfig";

const useContentfulClient = () => {
  return useMemo(() => createClient({ space, accessToken }), []);
};

const fetchImages = async (client) => {
  const response = await client.getEntries({
    content_type: "imageContent",
    include: 1,
  });

  const assets = response.includes?.Asset || [];
  const images = response.items[0]?.fields?.immaginiHome || [];

  const resolvedImages = images.map((image) => {
    const asset = assets.find((asset) => asset.sys.id === image.sys.id);
    return asset ? asset.fields.file.url : null;
  });

  return resolvedImages;
};

const useImages = () => {
  const client = useContentfulClient();
  const { data, error, isLoading } = useQuery({
    queryKey: ["images"],
    queryFn: () => fetchImages(client),
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 30,
    refetchOnWindowFocus: false,
  });

  return {
    images: data || [],
    error,
    loading: isLoading,
  };
};

const Home = () => {
  const { images, loading, error } = useImages();

  const displayedImages = images.slice(0, 1);
  const commonHeight = 600;

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "60vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <p>Errore: {error.message}</p>;
  }

  return (
    <Container maxWidth="lg" sx={{ paddingTop: 0, paddingBottom: 0 }}>
      <Grid container spacing={2}>
        <h1 class="visually-hidden">Emanuele Ricchena</h1>
        {displayedImages.map((url, index) => {
          if (!url) return null;
          return (
            <Grid item xs={12} key={index}>
              <Box>
                <img
                  src={url}
                  alt="Emanuele Ricchena Fashion Designer - Home Page"
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    // height: `${commonHeight}px`,
                    height: "100%",
                  }}
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
};

export default Home;
