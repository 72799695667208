const eventiStyles = (theme) => ({
  container: {
    paddingTop: theme.spacing(24),
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0),
    },
  },
  wrapper: {
    backgroundColor: theme.palette.secondary.light,
    height: "590px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(2),
      flexDirection: "column",
      height: "auto",
      gap: theme.spacing(12),
      width: "100%",
    },
  },
  calendarSection: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: theme.spacing(8),
    position: "relative",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      height: "auto",
      gap: theme.spacing(12),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: "100%",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    paddingRight: theme.spacing(10),
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: "100%",
    },
  },
  rightSide: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    zIndex: 1,
    maxWidth: "600px",
  },
  transparentBox: {
    backgroundColor: "#eedee",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 0,
    bottom: 0,
    width: "100%",
    zIndex: 0,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    height: "800px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  galleriaTransparentBox: {
    backgroundColor: "#eedee",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 0,
    bottom: 0,
    width: "100%",
    zIndex: 0,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    height: "700px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  videoTransparentBox: {
    backgroundColor: "#eedee",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 0,
    bottom: 0,
    width: "100%",
    zIndex: 0,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    height: "700px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  eventItem: {
    marginBottom: theme.spacing(2),
    position: "relative",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  sectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  galleriaWrapper: {
    marginTop: theme.spacing(32),
    backgroundColor: theme.palette.primary.light,
    height: "590px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      flexDirection: "column",
      height: "auto",
      gap: theme.spacing(12),
    },
  },
  videoWrapper: {
    marginTop: theme.spacing(32),
    backgroundColor: theme.palette.tertiary.light,
    height: "590px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      flexDirection: "column",
      height: "auto",
      gap: theme.spacing(12),
    },
  },
  contactWrapper: {
    marginTop: theme.spacing(32),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      flexDirection: "column",
      height: "auto",
      gap: theme.spacing(12),
    },
  },
  button: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  sliderContainer: {
    width: "646px",
    maxWidth: "100%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  iframeContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    zIndex: 1,
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  arrowButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    zIndex: 1,
    padding: "10px",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  nextArrow: {
    right: "10px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  prevArrow: {
    left: "10px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
});

export default eventiStyles;
