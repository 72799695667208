const serviziStyles = (theme) => {
  const styles = {
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(14),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
      marginTop: theme.spacing(6),
    },
    gridContainer: {
      // display: "flex",
      // alignItems: "center",
      // flexDirection: "row",
      // [theme.breakpoints.down("md")]: {
      //   flexDirection: "column",
      // },
    },
    imageBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "55vh",
    },
    image: {
      height: "auto",
      // maxHeight: "400px",
      width: "100%",
      objectFit: "contain",
      maxWidth: "30vw",
      [theme.breakpoints.down("md")]: {
        maxWidth: "90%",
      },
    },
    rightText: {
      lineHeight: "2",
      paddingLeft: theme.spacing(6),
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(2),
      },
    },
    lowerSection: {
      backgroundColor: theme.palette.primary.light,
      display: "flex",
      justifyContent: "flex-end",
      marginTop: theme.spacing(10),
      padding: theme.spacing(4),
      [theme.breakpoints.down("md")]: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(3),
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(4),
        padding: theme.spacing(2),
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(1),
      },
    },
    lowerText: {
      marginBottom: theme.spacing(4),
      textAlign: "left",
      paddingTop: theme.spacing(2),
    },
    serviceImg0: {
      position: "absolute",
      top: "0%",
      left: "8%",
      width: "30%",
    },
    serviceImg1: {
      position: "absolute",
      top: "53%",
      left: "23%",
      zIndex: 1,
      transform: "translateY(30%)",
      width: "40%",
      maxWidth: "40vw",
    },
    serviceImg2: {
      position: "absolute",
      top: "85%",
      left: "1%",
      transform: "translateY(10%)",
      width: "30%",
    },
  };
  styles.serviceImg0Mobile = {
    ...styles.serviceImg0,
    top: "-4%",
    left: "25%",
    width: "50%",
    maxWidth: "40vw",
  };
  styles.serviceImg1Mobile = {
    ...styles.serviceImg1,
    top: "15%",
    left: "43%",
    width: "60%",
    maxWidth: "50vw",
  };

  styles.serviceImg2Mobile = {
    ...styles.serviceImg2,
    top: "18%",
    left: "15%",
    width: "50%",
    maxWidth: "40vw",
  };

  styles.serviceImg0Smallest = {
    ...styles.serviceImg0,
    top: "-4%",
    left: "25%",
    width: "50%",
    maxWidth: "40vw",
  };
  styles.serviceImg1Smallest = {
    ...styles.serviceImg1,
    top: "7%",
    left: "43%",
    width: "60%",
    maxWidth: "50vw",
  };

  styles.serviceImg2Smallest = {
    ...styles.serviceImg2,
    top: "12%",
    left: "15%",
    width: "50%",
    maxWidth: "36vw",
  };

  return styles;
};

export default serviziStyles;
