const footerStyles = (theme) => ({
  footerContainer: {
    backgroundColor: "#191818", // Sfondo sempre nero
    color: "#FFFFFF", // Testo sempre bianco
    padding: theme.spacing(4, 0),
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  contactTitle: {
    fontFamily: theme.typography.bodoniFont,
    marginBottom: theme.spacing(2),
    fontWeight: "600",
    fontSize: "28px",
    color: "#FFFFFF", // Assicura che il titolo sia bianco
  },
  contactInfo: {
    display: "inline",
    paddingLeft: theme.spacing(1),
    color: "#FFFFFF", // Assicura che le informazioni di contatto siano bianche
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.875rem",
    },
  },
  contactInfoLink: {
    textDecoration: "none",
    color: "#FFFFFF",
  },
  socialIcons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    color: "#FFFFFF", // Assicura che le icone dei social siano bianche
  },
});

export default footerStyles;
