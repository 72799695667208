import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Divider,
  Button,
  useTheme,
  CircularProgress,
} from "@mui/material";
import allContentStyles from "../styles/AllContentStyles";

import useAllContent, { formatEventDate } from "../models/useAllContent";
import MediaModal from "../components/molecules/MediaModal";

const AllContent = () => {
  const theme = useTheme();
  const styles = allContentStyles(theme);

  const { events, images, videos, loading, error } = useAllContent();

  const [visibleEvents, setVisibleEvents] = useState(6);
  const [imageFilter, setImageFilter] = useState("Tutti");
  const [visibleImages, setVisibleImages] = useState(12);
  const [videoFilter, setVideoFilter] = useState("Tutti");
  const [visibleVideos, setVisibleVideos] = useState(6);

  const [openModal, setOpenModal] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [mediaType, setMediaType] = useState("");

  const handleLoadMoreImages = useCallback(
    () => setVisibleImages((prev) => prev + 6),
    []
  );
  const handleLoadMoreVideos = useCallback(
    () => setVisibleVideos((prev) => prev + 6),
    []
  );
  const handleLoadAllEvents = useCallback(
    () => setVisibleEvents(events.length),
    [events.length]
  );

  const handleImageFilterChange = useCallback((category) => {
    setImageFilter(category);
    setVisibleImages(6);
  }, []);

  const handleVideoFilterChange = useCallback((category) => {
    setVideoFilter(category);
    setVisibleVideos(6);
  }, []);

  const handleOpenModal = useCallback((media, type) => {
    setSelectedMedia(media);
    setMediaType(type);
    setOpenModal(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setSelectedMedia(null);
    setMediaType("");
  }, []);

  const imageFilters = images.reduce(
    (acc, image) => {
      if (!acc.includes(image.category)) {
        acc.push(image.category);
      }
      return acc;
    },
    ["Tutti"]
  );
  const videoFilters = videos.reduce(
    (acc, video) => {
      if (!acc.includes(video.category)) {
        acc.push(video.category);
      }
      return acc;
    },
    ["Tutti"]
  );

  const filteredImages = useMemo(() => {
    return imageFilter === "Tutti"
      ? images
      : images.filter((image) => image.category === imageFilter);
  }, [imageFilter, images]);

  const filteredVideos = useMemo(() => {
    return videoFilter === "Tutti"
      ? videos
      : videos.filter((video) => video.category === videoFilter);
  }, [videoFilter, videos]);

  const formattedEvents = useMemo(() => {
    return events.map((event) => ({
      ...event,
      formattedDate: formatEventDate(event.date),
    }));
  }, [events]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "55vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (error)
    return (
      <Typography variant="h6" color="error">
        Errore nel caricamento dei dati: {error.message}
      </Typography>
    );

  return (
    <Container maxWidth={false} disableGutters sx={styles.container}>
      {/* Contenuto Eventi */}
      <Typography variant="h3" sx={styles.sectionTitle}>
        Tutti gli Eventi in Programma
      </Typography>
      <Grid container spacing={4}>
        {formattedEvents.slice(0, visibleEvents).map((event, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Box sx={styles.eventItem}>
              {/* Mostra video o immagine */}
              {event.type === "video" ? (
                event.video.includes("youtube.com") ||
                event.video.includes("vimeo.com") ? (
                  <iframe
                    width="100%"
                    height="250"
                    src={event.video}
                    title={event.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                      borderRadius: "4px",
                      border: "2px solid rgba(0, 0, 0, 0.1)",
                    }}
                  ></iframe>
                ) : (
                  <video
                    controls
                    style={{
                      width: "100%",
                      height: "auto",
                      objectFit: "cover",
                      borderRadius: "4px",
                      border: "2px solid rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <source src={event.video} type="video/mp4" />
                    Il tuo browser non supporta la riproduzione video.
                  </video>
                )
              ) : (
                <img
                  src={event.image}
                  alt={event.title}
                  style={{
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    borderRadius: "4px",
                    border: "2px solid rgba(0, 0, 0, 0.1)",
                  }}
                  loading="lazy"
                />
              )}

              <Typography variant="h5" sx={styles.eventTitle}>
                {event.title}
              </Typography>
              <Divider sx={styles.divider} />
              <Typography variant="body1" sx={styles.eventDateLocation}>
                {event.formattedDate}
              </Typography>
              <Typography variant="h6Bodoni" sx={styles.eventDateLocation}>
                {event.location}
              </Typography>
              <Divider sx={styles.divider} />
              <Typography variant="body2" sx={styles.eventDescription}>
                {event.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Carica Tutti gli Eventi */}
      {/* {visibleEvents < events.length && (
        <Box sx={{ textAlign: "center", marginTop: theme.spacing(4) }}>
          <Button
            variant="contained"
            onClick={handleLoadAllEvents}
            sx={{
              color: "#fff",
              backgroundColor: "#000",
              "&:hover": { backgroundColor: "#555" },
            }}
          >
            Carica Tutti gli Eventi
          </Button>
        </Box>
      )} */}

      {/* Sezione Galleria Immagini */}
      <Box sx={styles.gallerySection}>
        <Typography variant="h3" sx={styles.sectionTitle}>
          Galleria Immagini
        </Typography>
        <Box sx={styles.filterContainer}>
          {imageFilters?.map((category) => (
            <Button
              key={category}
              variant={imageFilter !== category ? "" : "outlined"}
              sx={styles.filterButton}
              onClick={() => handleImageFilterChange(category)}
            >
              {category}
            </Button>
          ))}
        </Box>
        <Grid container spacing={2}>
          {filteredImages?.map((image, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={styles.imageItem}
                onClick={() => handleOpenModal(image.url, "image")}
              >
                <img
                  src={image.url}
                  alt={`Gallery ${index}`}
                  style={{
                    width: "100%",
                    height: "40vh",
                    objectFit: "contain",
                    borderRadius: "4px",
                    backgroundColor: "#f0f0f0",
                  }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>

        {/* {events.length > 0 && (
          <Box sx={{ textAlign: "center", marginTop: theme.spacing(4) }}>
            <Button
              variant="contained"
              onClick={handleLoadAllEvents}
              sx={{
                color: "#fff",
                backgroundColor: "#000",
                "&:hover": { backgroundColor: "#555" },
              }}
            >
              Carica Tutti gli Eventi
            </Button>
          </Box>
        )} */}
      </Box>

      {/* Sezione Galleria Video */}
      <Box sx={styles.videoSection}>
        <Typography variant="h3" sx={styles.sectionTitle}>
          Galleria Video
        </Typography>
        <Box sx={styles.filterContainer}>
          {videoFilters?.map((category) => (
            <Button
              key={category}
              variant={videoFilter !== category ? "" : "outlined"}
              sx={styles.filterButton}
              onClick={() => handleVideoFilterChange(category)}
            >
              {category}
            </Button>
          ))}
        </Box>
        <Grid container spacing={2}>
          {filteredVideos?.map((video, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={styles.videoItem}
                onClick={() => handleOpenModal(video.url, "video")}
              >
                <iframe
                  width="100%"
                  height="500px"
                  src={video.url}
                  title={`Video ${index}`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{ borderRadius: "4px", cursor: "pointer" }}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
        {visibleVideos < videos.length && (
          <Box sx={{ textAlign: "center", marginTop: theme.spacing(4) }}>
            <Button
              variant="contained"
              onClick={handleLoadMoreVideos}
              sx={{
                color: "#fff",
                backgroundColor: "#000",
                "&:hover": { backgroundColor: "#555" },
              }}
            >
              Carica Più Video
            </Button>
          </Box>
        )}
      </Box>

      {/* Modal per immagine o video ingrandito */}
      <MediaModal
        open={openModal}
        handleClose={handleCloseModal}
        mediaType={mediaType}
        mediaUrl={selectedMedia}
      />
    </Container>
  );
};

export default AllContent;
