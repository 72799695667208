import { createTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#3247A6",
      light: alpha("#3247A6", 0.2),
    },
    secondary: {
      main: "#B41229",
      light: alpha("#B41229", 0.2),
    },
    tertiary: {
      main: "#434E38",
      light: alpha("#434E38", 0.2),
    },
    neutral: {
      900: "#000000",
      800: "#C7BCBA",
      700: "#C4C4C4",
      400: "#D7D7D7",
      200: "#FFFFFF",
    },
    background: {
      default: "#FFFDFD",
    },
    text: {
      primary: "#000000",
      secondary: "#FFFFFF",
    },
    whiteLight: alpha("#FFFFFF", 0.2),
  },
  typography: {
    fontFamily: "'Montserrat', sans-serif",
    bodoniFont: "'Bodoni Moda', serif",
    cursiveFont: "Petit Formal Script, cursive",

    h1: {
      fontFamily: "'Bodoni Moda', serif",
      fontWeight: 700,
      fontSize: "6rem",
      lineHeight: 1.2,
      [createTheme().breakpoints.down("md")]: {
        fontSize: "3rem",
      },
      [createTheme().breakpoints.down("sm")]: {
        fontSize: "1.8rem",
      },
    },
    h2: {
      fontFamily: "'Bodoni Moda', serif", // Font per titoli H2
      fontWeight: 700,
      fontSize: "64px",
      lineHeight: 1.3,
      [createTheme().breakpoints.down("md")]: {
        fontSize: "2.5rem",
      },
      [createTheme().breakpoints.down("sm")]: {
        fontSize: "2rem",
      },
    },
    h3: {
      fontFamily: "'Bodoni Moda', serif", // Font per titoli H3
      fontWeight: 600,
      fontSize: "48px",
      [createTheme().breakpoints.down("md")]: {
        fontSize: "2rem",
      },
      [createTheme().breakpoints.down("sm")]: {
        fontSize: "1.75rem",
      },
    },
    h4: {
      fontFamily: "'Montserrat', sans-serif", // Font per titoli H4
      fontWeight: 400,
      fontSize: "32px",
    },
    h5: {
      fontFamily: "'Montserrat', sans-serif", // Font per titoli H5
      fontWeight: 400,
      fontSize: "20px",
    },
    h6Bodoni: {
      fontFamily: "'Bodoni Moda', serif", // Stile aggiuntivo per H6
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: 1.5,
    },
    body1: {
      fontFamily: "'Montserrat', sans-serif", // Font per corpo del testo (paragrafi)
      fontSize: "1.2rem",
    },
    body2: {
      fontFamily: "'Montserrat', sans-serif", // Font per testi secondari
      fontSize: "1rem",
    },
    button: {
      fontFamily: "'Montserrat', sans-serif", // Font per bottoni
      fontWeight: 600,
      textTransform: "uppercase",
      fontSize: "1rem",
    },
  },
  spacing: 8, // Definisce la spaziatura di base per il layout
  breakpoints: {
    values: {
      xs: 0, // Extra small devices (e.g., phones)
      sm: 600, // Small devices (e.g., tablets)
      md: 900, // Medium devices (e.g., small laptops)
      lg: 1200, // Large devices (e.g., desktops)
      xl: 1536, // Extra large devices (e.g., big desktops)
    },
  },
});

export default lightTheme;
