import React, { useState } from "react";
import {
  Box,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import PrimaryButton from "../components/atoms/PrimaryButton";
import { useNavigate } from "react-router-dom";

import serviziStyles from "../styles/ServiziStyle";
import ModalServizi from "../components/molecules/ModalServizi";
import useServicesContent from "../models/useServicesContent";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Servizi = () => {
  const theme = useTheme();
  const styles = serviziStyles(theme);
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // max width 960px
  const isDesktop = useMediaQuery(theme.breakpoints.up("md")); // min width 960px
  const isSmallest = useMediaQuery(theme.breakpoints.down("sm")); // max width 600px

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: "",
    content: "",
    imagesArray: [],
  });

  const { services, loading, error } = useServicesContent();

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "55vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // if (error || !services?.fields) {
  //   return <div>Error loading services data...</div>;
  // }

  const { servicesImages = [] } = services.fields;

  const handleContactClick = () => {
    navigate("/eventi", { state: { scrollToContact: true } });
  };

  const handleOpenModal = (title, content, imagesArray) => {
    setModalContent({ title, content, imagesArray });
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Container maxWidth="lg" sx={styles.container} height="fit-content">
      <>
        <Grid
          container
          spacing={2}
          sx={styles.gridContainer}
          position="relative"
          height={"fit-content"}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              ...styles.imageBox,
              height: isMobile ? "45vh" : isDesktop ? "500px" : "78vh",
            }}
            width="100%"
          >
            <Box>
              {services &&
                servicesImages?.map((image, index) =>
                  isSmallest ? (
                    <img
                      style={{
                        ...styles.image,
                        ...styles[`serviceImg${index}Smallest`],
                      }}
                      key={index}
                      src={image.fields.file.url}
                      alt={image.fields.description}
                    />
                  ) : isMobile ? (
                    <img
                      style={{
                        ...styles.image,
                        ...styles[`serviceImg${index}Mobile`],
                      }}
                      key={index}
                      src={image.fields.file.url}
                      alt={image.fields.description}
                    />
                  ) : (
                    <img
                      style={{
                        ...styles.image,
                        ...styles[`serviceImg${index}`],
                      }}
                      key={index}
                      src={image.fields.file.url}
                      alt={image.fields.description}
                    />
                  )
                )}
            </Box>
          </Grid>

          {/* Colonna Testo "servizi" */}
          <Grid item xs={12} sm={12} md={6} sx={styles.rightText}>
            {documentToReactComponents(services?.fields?.servicesText)}

            {/* <Divider
            sx={{ height: "2px", backgroundColor: "black", marginTop: "100px" }}
          /> */}
          </Grid>
        </Grid>

        <Grid container spacing={8} sx={styles.lowerSection}>
          <Grid item xs={12} md={6} sx={styles.lowerText}>
            <Typography
              variant="h5"
              fontWeight={"bold"}
              fontSize="1.4rem"
              marginBottom={"12px"}
            >
              Cosa realizzo:
            </Typography>
            <ul style={{ paddingLeft: "1rem" }}>
              {services?.fields?.listaCosaRealizzo?.map((item, index) => (
                <li>
                  <Typography
                    key={index}
                    variant="body1"
                    sx={{
                      cursor: "pointer",
                      textDecoration: "none",
                      "&:hover": { color: theme.palette.primary.main },
                    }}
                    onClick={() =>
                      handleOpenModal(
                        item,
                        documentToReactComponents(
                          services?.fields[`cosaRealizzoModal${index}Text`]
                        ),
                        services?.fields[`cosaRealizzoModal${index}Images`]
                      )
                    }
                  >
                    {item}
                  </Typography>
                </li>
              ))}
            </ul>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: theme.spacing(4),
              }}
            >
              <PrimaryButton onClick={handleContactClick}>
                CONTATTAMI
              </PrimaryButton>
            </Box>
          </Grid>
        </Grid>
      </>

      {/* Modal */}
      <ModalServizi
        open={modalOpen}
        onClose={handleCloseModal}
        title={modalContent.title}
        description={modalContent.content}
        images={modalContent.imagesArray}
      />
    </Container>
  );
};

export default Servizi;
