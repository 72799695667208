import React from "react";
import Slider from "react-slick";
import { Box, Grid } from "@mui/material";

const CarouselComponent = ({ images, sliderSettings }) => {
  const limitedImages = images.slice(0, 8);

  return (
    <Slider {...sliderSettings}>
      {limitedImages.map((image, index) => (
        <Box key={index}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <img
                src={image.url}
                alt={image.title}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                  maxHeight: "400px",
                }}
                loading="lazy"
              />
            </Grid>
          </Grid>
        </Box>
      ))}
    </Slider>
  );
};

export default CarouselComponent;
