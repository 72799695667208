import React from "react";
import {
  Box,
  Typography,
  Grid,
  IconButton,
  useTheme,
  Link,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

import footerStyles from "../../styles/FooterStyles";
import useContactInfoContent from "../../models/useContactInfo";

const Footer = () => {
  const theme = useTheme();
  const styles = footerStyles(theme);
  const { contactInfo, loading, error } = useContactInfoContent();

  return (
    <Box sx={styles.footerContainer}>
      <Typography variant="h4" sx={styles.contactTitle}>
        Contatti
      </Typography>

      <Box
        container
        display={"flex"}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap={6}
      >
        <Grid item>
          <PhoneIcon />
          <Typography variant="body1" sx={styles.contactInfo}>
            <Link sx={styles.contactInfoLink} href="tel:+3937145896532">
              {contactInfo?.fields?.telefono}
            </Link>
          </Typography>
        </Grid>
        <Grid item>
          <EmailIcon />
          <Typography variant="body1" sx={styles.contactInfo}>
            <Link
              sx={styles.contactInfoLink}
              href={`mailto:${contactInfo?.fields?.email}`}
            >
              {contactInfo?.fields?.email}
            </Link>
          </Typography>
        </Grid>
      </Box>

      <Box sx={styles.socialIcons}>
        <IconButton
          aria-label="Instagram"
          href={contactInfo?.fields?.linkInstagram}
          target="_blank"
        >
          <InstagramIcon sx={styles.socialIcon} />
        </IconButton>
        <IconButton
          aria-label="Facebook"
          href={contactInfo?.fields?.linkFacebook}
          target="_blank"
        >
          <FacebookIcon sx={styles.socialIcon} />
        </IconButton>
        <IconButton
          aria-label="TikTok"
          href={contactInfo?.fields?.linkTiktok}
          target="_blank"
        >
          <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.4668 11C1.4668 6.522 1.4668 4.282 2.86264 2.891C4.25849 1.5 6.50529 1.5 10.9999 1.5C15.4935 1.5 17.7413 1.5 19.1372 2.891C20.533 4.282 20.533 6.521 20.533 11C20.533 15.478 20.533 17.718 19.1372 19.109C17.7413 20.5 15.4945 20.5 10.9999 20.5C6.5063 20.5 4.25849 20.5 2.86264 19.109C1.4668 17.718 1.4668 15.479 1.4668 11Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.53125 10.008C8.70839 9.89196 6.83187 10.083 5.91268 11.778C4.99349 13.473 5.91971 15.237 6.49872 15.907C7.0697 16.534 8.885 17.721 10.8107 16.562C11.2883 16.275 11.8834 16.06 12.5578 13.815L12.4795 4.98096C12.349 5.95396 13.4278 8.23596 16.4974 8.50596"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Footer;
