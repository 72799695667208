const bioProfiloStyles = (theme) => ({
  container: {
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(14),
  },
  gridContainer: {
    position: "relative",
  },
  imageBox: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: { xs: "300px", md: "800px" },
  },
  image: {
    width: { xs: "80%", md: "100%" },
    objectFit: "cover",
    maxHeight: "600px",
    [theme.breakpoints.down("md")]: {
      maxHeight: "400px",
    },
  },
  coloredBox: {
    position: "absolute",
    bottom: { xs: "-10px", md: "20px" },
    left: { xs: "0", md: "-200px" },
    backgroundColor: theme.palette.primary.light,
    height: { xs: "300px", md: "800px" },
    width: { xs: "100%", md: "calc(100% + 100px)" },
    zIndex: -1,
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.text.primary,
    textTransform: "uppercase",
    lineHeight: "1.6",
  },
  bioText: {
    marginTop: "2rem",
    marginBottom: theme.spacing(4),
    whiteSpace: "break-spaces",
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: "2",
    color: theme.palette.text.primary,
    textAlign: "justify",
  },
  subtitle: {
    fontFamily: theme.typography.bodoniFont,
    fontWeight: "bold",
    textAlign: "right",
    color: theme.palette.text.primary,
  },
});

export default bioProfiloStyles;
