import React from "react";
import { Box, Button, Typography } from "@mui/material";
import CollectionsDropdown from "./CollectionDropDown";

const DesktopNav = ({
  pages,
  currentPage,
  onPageChange,
  onCollectionChange,
  theme,
}) => {
  const textColorPrimary = theme.palette.text.primary;
  const backgroundColor = theme.palette.background.default;
  const hoverColor = theme.palette.primary.main;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(4),
        marginTop: theme.spacing(2),
        backgroundColor: backgroundColor,
      }}
    >
      {pages.map((page) => (
        <Button
          key={page.name}
          onClick={() => onPageChange(page)}
          sx={{
            color: textColorPrimary,
            fontFamily: theme.typography.fontFamily,
            fontWeight: "400",
            textTransform: "none",
            fontSize: theme.typography.button.fontSize,
            "&:hover": {
              backgroundColor: hoverColor,
              color: theme.palette.text.secondary,
            },
          }}
        >
          <Typography variant="h5"> {page.name}</Typography>
        </Button>
      ))}

      {/* Dropdown per le collezioni */}
      <CollectionsDropdown onCollectionSelect={onCollectionChange} />
    </Box>
  );
};

export default DesktopNav;
