import React, { useEffect, useState } from "react";
import { AppBar, Box, Toolbar, Typography, Container } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom"; // Aggiungi useLocation
import NavDrawer from "./NavDrawer";
import DesktopNav from "./DesktopNav";
import HamburgerMenu from "./HamburgherMenu";

const pages = [
  { name: "Home", path: "/" },
  { name: "Profilo", path: "/profilo" },
  { name: "Servizi", path: "/servizi" },
  { name: "Eventi", path: "/eventi" },
];

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState("Home");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLgUp = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const location = useLocation(); // Per monitorare il percorso corrente
  const isHomePage = location.pathname === "/";

  // Funzione per ottenere il nome della pagina in base al percorso
  const getPageNameFromPath = (path) => {
    const matchingPage = pages.find((page) => page.path === path);

    if (matchingPage) {
      return matchingPage.name;
    } else if (path === "/all-content") {
      return "Eventi"; // Gestione specifica per la pagina all-content
    } else if (path.startsWith("/collezioni")) {
      const collectionName =
        path.split("#")[1]?.replace(/-/g, " ") || "Collezioni";
      return collectionName.charAt(0).toUpperCase() + collectionName.slice(1);
    } else {
      return "Emanuele Ricchena"; // Valore predefinito
    }
  };

  useEffect(() => {
    const path = location.pathname + location.hash; // Aggiungi location.hash per le collezioni
    const pageName = getPageNameFromPath(path);
    setCurrentPage(pageName); // Aggiorna il titolo corrente in base al percorso
  }, [location]); // Esegui l'effetto ogni volta che cambia la posizione

  const handleOpenNavMenu = () => setDrawerOpen(true);
  const handleCloseNavMenu = () => setDrawerOpen(false);

  const handlePageChange = (page) => {
    setCurrentPage(page.name);
    navigate(page.path);
    handleCloseNavMenu();
  };

  const handleCollectionChange = (collectionName) => {
    const formattedName = collectionName.toLowerCase().replace(/\s+/g, "-");
    setCurrentPage(collectionName);
    navigate(`/collezioni#${formattedName}`);
    handleCloseNavMenu();
  };

  const handleCollectionSelect = (collectionName) => {
    const formattedName = collectionName.toLowerCase().replace(/\s+/g, "-");
    navigate(`/collezioni#${formattedName}`);
    setDrawerOpen(false);
  };

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Container
        maxWidth="xl"
        sx={{
          paddingLeft: { xs: 0, sm: 0 },
          paddingRight: { xs: 0, sm: 0 },
          position: "relative",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            flexDirection: currentPage !== "Home" ? "column" : "row",
            justifyContent: "center",
            alignItems: isMobile ? "flex-start" : "center",
            paddingTop: theme.spacing(2),
            paddingLeft: { xs: "12px", md: "0px" },
            paddingBottom: theme.spacing(1),

            backgroundColor: {
              xs: "#000000", // Sfondo nero fisso per mobile
              md: "transparent", // Trasparente per desktop
            },
          }}
        >
          {/* Logo */}
          <Box
            sx={{
              marginTop: theme.spacing(1.25),
              marginBottom: theme.spacing(1.25),
              marginLeft: { xs: "20px", md: "0px" },
              marginRight: { xs: "20px", md: "0px" },
            }}
          >
            {currentPage !== "Home" ? (
              <Typography
                variant="h1"
                noWrap
                onClick={() => handlePageChange(pages[0])}
                sx={{
                  textDecoration: "none",
                  color: {
                    xs: "#FFFFFF", // Testo bianco fisso per mobile
                    md: theme.palette.text.primary,
                  },
                  cursor: "pointer",
                }}
              >
                {currentPage}
              </Typography>
            ) : null}
          </Box>

          {/* Hamburger menu */}
          {
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "12px",
                marginLeft: { xs: "0px", md: "0px" },
                marginRight: { xs: "20px", sm: "16px", md: "0px" },
                position: "absolute",
                right: { xs: "0px", sm: "1%", md: "2%", lg: "5%", xl: "8%" },
              }}
            >
              <HamburgerMenu
                onClick={handleOpenNavMenu}
                iconColor="white"
                backgroundColor="rgba(255, 255, 255, 0.3)"
              />
            </Box>
          }

          {/* Desktop navigation */}
          {isLgUp && !isHomePage && (
            <DesktopNav
              pages={pages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onCollectionChange={handleCollectionChange}
              theme={theme}
            />
          )}

          {/* Drawer (Mobile menu e Desktop menu) */}
          <NavDrawer
            open={drawerOpen}
            onClose={handleCloseNavMenu}
            pages={pages}
            onNavigate={handlePageChange}
            onCollectionSelect={handleCollectionSelect}
            isHomePage={isHomePage}
          />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
