import React, { useState } from "react";
import { Menu, MenuItem, Button, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "@mui/material/styles";

const CollectionsDropdown = ({ onCollectionSelect, isMobile }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCollectionClick = (collectionName) => {
    onCollectionSelect(collectionName);
    handleClose();
  };

  return (
    <div>
      <Button
        aria-controls="collections-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        sx={{
          color: theme.palette.text.primary,
          fontSize: isMobile ? "1.2rem" : "1rem",
          justifyContent: isMobile ? "flex-start" : "center",
          textTransform: "uppercase",
          fontWeight: 400,
        }}
      >
        Collezioni
      </Button>
      <Menu
        id="collections-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.neutral[900]
                : theme.palette.background.paper,
            color:
              theme.palette.mode === "dark"
                ? theme.palette.text.secondary
                : theme.palette.text.primary,
          },
        }}
      >
        <MenuItem
          onClick={() => handleCollectionClick("Fashion Feelings")}
          sx={{
            color:
              theme.palette.mode === "dark"
                ? theme.palette.text.secondary
                : theme.palette.text.primary,
            "&:hover": {
              backgroundColor: theme.palette.action.hover,
            },
          }}
        >
          Fashion Feelings
        </MenuItem>
      </Menu>
    </div>
  );
};

export default CollectionsDropdown;
